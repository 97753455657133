.ljungblut-videos {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.youtube-wrapper {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}
.youtube-wrapper iframe {
  width: 100%;
  height: 99%;
}
.youtube-wrapper .close {
  position: absolute;
  top: 85px;
  right: 45px;
  z-index: 5;  
  cursor: pointer;
}
.youtube-wrapper svg {
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: all .3s ease-in;
}
.youtube-wrapper:hover svg {
  opacity: 1;
}
.end svg {
  width: 35px;
  height: 35px;
  padding: 5px;    
}
.end svg:hover .svg-bg {
  transition: all .1s ease-in;
  fill: #ffffff;
}
.end small {
  display: block;
  font-size: 10px;
  padding: 0 10px;
}
.end small a {
  color: inherit;
}
.end small a:hover, .end small a:visited, .end small a:active {
  text-decoration: none;
}
@media (hover: none) {
  .youtube-wrapper svg {
    opacity: 1;
  }
}

@media (min-width: 860px) and (orientation: landscape) {
  .end svg {
    width: 50px;
    height: 50px;
    padding: 10px;
  }
  .end small {
    font-size: 12px;
    padding: 0 10px;
  }
}

@media (min-width: 1200px) and (orientation: landscape) {
  .end small {
    padding: 0 40px;
  }
}